@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&family=Roboto:wght@500&display=swap);
* {
  min-height: 0;
}
body,
html {
  height: 100%;
}
body {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: #fff;
  background-color: #1b1c1f;
  overflow: hidden;
}
/*fonts*/
.bebas-font {
  font-family: 'Bebas Neue', cursive !important;
  letter-spacing: 0.05em;
}
.font10 {
  font-size: 10px;
}
.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font24 {
  font-size: 24px;
}
.font34 {
  font-size: 34px;
}
.font48 {
  font-size: 48px;
}
.font-normal {
  font-weight: normal !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.text-violate {
  color: #a2a5d7 !important;
}
.text-dark {
  color: #a7b0b5 !important;
}
.text-green {
  color: #7baa64 !important;
}
.text-red,
.text-error,
.text-danger {
  color: #dc3545 !important;
}
.text-underline {
  text-decoration: underline;
}
p {
  line-height: normal;
}
small.note {
  color: #a7b0b5;
}
.border-bottom {
  border-color: #1b1c1f !important;
}
.bg-darker {
  background-color: #1b1c1f !important;
}
.bg-green {
  background-color: #7baa64 !important;
}
.bg-yellow {
  background-color: rgb(246, 207, 3) !important;
}
.bg-primary,
.badge-primary {
  background-color: #6e03f6 !important;
}
.text-primary {
  color: #6e03f6 !important;
}
.link.text-dark:hover {
  text-decoration: underline;
  color: #a7b0b5 !important;
}
.rounded10 {
  border-radius: 10px !important;
}

/**/
.desknotification-box {
  height: 50px;
  display: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  background-color: rgb(162, 165, 215);
  flex: 0 0 auto;
  justify-content: flex-start;
  padding: 0 12px;
}
.desknotification-box p {
  width: 100%;
}
.desknotification-box .close {
  text-shadow: none;
  font-size: 18px;
  color: #fff;
}
.desk-noti .desknotification-box {
  display: flex;
}
.desk-noti header .open-menu {
  top: 110px !important;
}
.desk-noti .right-panel-mobile .tab-content > .active {
  top: 110px !important;
}
/**/

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgb(110 3 246 / 25%);
}
/*btn*/
.btn {
  box-shadow: none !important;
}
.btn.btn-sm {
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
}
.btn.btn-md {
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
}
.btn.btn-lg {
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
}

.btn-primary {
  color: #fff;
  background-color: #6e03f6 !important;
  border-color: #6e03f6 !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #6e03f6;
  border-color: #6e03f6;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 90, 90, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #6e03f6;
  border-color: #6e03f6;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6e03f6;
  border-color: #000;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 3, 246, 0.5);
}

.btn-outline-primary {
  color: #6e03f6;
  background-color: transparent;
  background-image: none;
  border-color: #6e03f6;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #6e03f6;
  border-color: #6e03f6;
}
.btn-outline-primary:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 3, 246, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #6e03f6;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #6e03f6;
  border-color: #6e03f6;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(110, 3, 246, 0.5);
}

.btn.btn-outline-primary {
  color: #fff;
}
.btn.btn-outline-light {
  border: 1px solid #3d3f5e;
}
.btn.btn-outline-light:hover {
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}
.btn.radius10 {
  border-radius: 10px;
}
button {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  background: transparent;
  border: none;
}
button:focus {
  outline: none !important;
}
/*structure*/
.wrapper {
  height: 100vh;
}

header.landing {
  position: absolute !important;
}
header {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  height: 60px;
  z-index: 100;
  position: absolute;
  width: 100%;
  max-width: 1920px;
}
header .topbar {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  z-index: 30;
  background-color: rgb(27, 28, 31);
}
header .status-bar {
  flex-flow: row nowrap;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}
header .status-bar .bebas-font {
  letter-spacing: 0.05em;
}
header .user-info {
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 400px;
  background: #222327;
  padding: 0;
}
header .menu-btn:focus,
header .menu-btn.focus {
  box-shadow: none;
}
.main {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 60px;
}
.main .left-section {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background: #222327;
  width: 272px;
  position: fixed;
  z-index: 1;
  height: 100%;
  left: 0;
}
.main .page {
  flex-flow: column nowrap;
  flex: 1 1;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  background-color: #1b1c1f;
}
.main .page .content {
  display: flex;
  flex-flow: inherit;
  flex: 1 1;
  padding: 30px;
  overflow: hidden auto;
  padding-bottom: 50px;
  background-color: #1b1c1f;
}
.main .right-section {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 400px;
  justify-content: flex-start;
  align-items: stretch;
  background: #222327;
  width: 300px;
  overflow-y: auto;
  height: 100%;
  right: 0;
  z-index: 0;
}
header .menu-btn {
  margin-left: 10px;
}
header .user-info .border-bottom-dark {
  border-bottom: 1px solid #1b1c1f !important;
}
header .user-info .dropdown-menu.show body {
  overflow-y: hidden;
}
header a.user-dropdown {
  color: #fff;
  display: block;
  width: 100%;
  position: relative;
}
header a.user-dropdown:hover {
  text-decoration: none;
  cursor: pointer;
}
header a.user-dropdown img {
  margin-right: 15px;
}
header a.user-dropdown .online {
  background-color: rgb(123, 170, 100);
  border: 2px solid #222327;
  width: 12px;
  height: 12px;
  border-radius: 15px;
  position: absolute;
  left: 18px;
  bottom: -3px;
}
header a.user-dropdown .offline {
  background-color: rgb(85, 87, 122);
  border: 2px solid rgb(27, 28, 31);
  width: 12px;
  height: 12px;
  border-radius: 15px;
  position: absolute;
  left: 18px;
  bottom: -3px;
}
header a.user-dropdown::after {
  display: block;
  content: '';
  width: 15px;
  height: 15px;
  background: url(/static/media/arrow.a2919472.svg) no-repeat;
  background-size: 10px;
  position: absolute;
  top: 12px;
  right: 10px;
}
header a.open.user-dropdown::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  top: 2px;
  right: 15px;
}
header .open-menu {
  display: none;
  width: 300px;
  position: fixed;
  right: 0;
  top: 60px;
  background-color: #27282b;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  height: calc(100vh - 60px);
  z-index: 102 !important;
}
header .open-menu.show {
  display: block;
  position: fixed !important;
  bottom: 0;
  right: 0px !important;
  left: auto !important;
  overflow-y: auto;
}
header .open-menu::before {
  border-bottom: 9px solid rgba(0, 0, 0, 0.2);
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: '';
  display: inline-block;
  left: 8%; /* position */
  position: absolute;
  top: -8px;
}
header .open-menu::after {
  border-bottom: 8px solid #27282b;
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  content: '';
  display: inline-block;
  left: 8%; /* position */
  position: absolute;
  top: -7px;
}
header .user-info .open-menu ul {
  list-style: none;
  margin: 0px;
  padding: 0 0 15px;
  border-bottom: 1px solid #1b1c1f;
}
header .user-info .open-menu ul li {
  width: 100%;
  color: #fff;
  font-size: 14px;
}
header .user-info .open-menu li a {
  font-size: 14px;
  display: inline-flex;
  color: rgb(167, 176, 181);
  align-items: center;
  padding: 20px 15px;
  width: 100%;
}
header .user-info .open-menu li a:hover {
  text-decoration: none;
  background-color: rgb(44, 46, 51);
}
header .user-info .open-menu li.border-bottom {
  border-bottom: 1px solid #1b1c1f !important;
}
header .user-info .open-menu li.active a, .menu li a.active {
  color: #fff;
  background-color: rgb(44, 46, 51);
}
/*align*/
.v60 {
  height: 60px;
}
.border-bottom-dark {
  border-bottom: 1px solid #000;
}
/*left-menu*/
.menu {
  list-style: none;
  margin: 0px;
  padding: 0 0 15px;
  border-bottom: 1px solid #1b1c1f;
  overflow-y: auto;
  padding-bottom: 100px;
}
.menu li {
  width: 100%;
  margin-bottom: 10px;
}
.menu li a {
  display: inline-flex;
  color: rgb(167, 176, 181);
  align-items: center;
  padding: 5px 15px;
  width: 100%;
}
.menu li a:hover {
  text-decoration: none;
  background-color: rgb(44, 46, 51);
}
.menu li a i {
  background-color: #3d3f5e;
  width: 30px;
  height: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 100px;
  margin-right: 10px;
}
.menu li.border-bottom {
  border-bottom: 1px solid #1b1c1f !important;
}
.menu li a i img {
  width: 15px;
  object-fit: contain;
}
.menu li.active a {
  color: #fff;
  background-color: rgb(44, 46, 51);
}
.menu li.active a i {
  background: #a2a5d7;
}
/*right-nav*/
.right-panel .panel-tabs a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
  position: relative;
  border-bottom: 1px solid #000000;
}
.right-panel .panel-tabs a i {
  opacity: 0.6;
  display: block;
  width: 20px;
  height: 15px;
}
.right-panel .panel-tabs a.active {
  border-bottom: 1px solid #6e03f6;
}
.right-panel .panel-tabs a.active i {
  opacity: 1;
}
.right-panel .panel-tabs a i.forum-icon {
  background-size: 15px;
}
.right-panel .panel-tabs a i.users-icon {
  background: url(/static/media/users_icon.49413bd9.svg) no-repeat center center;
  background-size: 20px;
}
.right-panel .panel-tabs a i.bell-icon {
  background-size: 15px;
}
.right-panel .panel-tabs a i {
  opacity: 0.6;
}
.right-panel .panel-tabs a .badge {
  position: absolute;
  top: 10px;
  left: 54%;
}
.right-panel .join {
  padding: 15px 0px;
  background: #1b1c1f;
  font-size: 12px;
  width: 280px;
  position: relative;
  top: auto;
  right: 0;
}
.right-panel .join a {
  color: #fff;
}
.right-panel .join a:hover {
  text-decoration: none;
}
.right-panel .forum .tab-body {
  padding-top: 15px !important;
  height: calc(100vh - 160px) !important;
}
.right-panel .forum .chat-box {
  padding: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: auto;
  width: 280px;
  background: #222327;
}
.right-panel .forum .chat-box .form-control.chat {
  border-radius: 30px;
  font-size: 12px;
  background: rgb(22, 23, 26);
  border-color: #222327;
  width: 90%;
  color: #fff;
  width: 100%;
  margin-right: 15px;
}
.right-panel .forum .chat-box .upload-btn {
  padding-right: 10px;
  -webkit-filter: invert(71%) sepia(2%) saturate(161%) hue-rotate(169deg) brightness(84%) contrast(86%);
          filter: invert(71%) sepia(2%) saturate(161%) hue-rotate(169deg) brightness(84%) contrast(86%);
}
.right-panel .tab-content {
  margin-top: 46px;
  height: 100%;
}
.right-panel .tab-content p {
  font-size: 12px;
}
.right-panel .tab-content p .muser {
  font-weight: 700;
  color: #a2a5d7;
}
.right-panel .tab-content p .xuser {
  font-weight: 700;
  color: #7baa64;
}
.right-panel .fixed-tabs {
  position: fixed;
  background: #222327;
  right: 0;
  width: 400px;
}
.right-panel .tab-content .tab-body {
  overflow-y: auto;
  height: 100%;
}
.right-panel .tab-content ul.notification {
  list-style: none;
  padding: 0;
  margin: 0;
}
.right-panel .tab-content ul.notification a {
  align-items: center;
  color: #fff;
  font-size: 12px;
  flex-flow: row nowrap;
  flex: 1 1;
  justify-content: flex-start;
  cursor: pointer;
  padding: 15px 10px;
  -webkit-user-select: none;
          user-select: none;
}
.right-panel .tab-content ul.notification a:hover {
  text-decoration: none;
  background-color: #6e03f6;
}
.right-panel .tab-content ul.notification a i {
  flex-flow: inherit;
  flex: 15 1;
  margin: auto;
  max-width: 40px;
}
.right-panel .tab-content ul.notification a img {
  width: 30px;
}
.right-panel .tab-content ul.notification a span {
  flex-flow: inherit;
  flex: 60 1;
  margin: auto;
  padding-right: 10px;
  line-height: normal;
}
.right-panel .tab-content ul.notification a small {
  flex-flow: inherit;
  flex: 0 0 auto;
  font-size: 12px;
  color: rgb(162, 165, 215);
  margin-right: 1ex;
}
.right-panel .tab-content .form-control.search {
  color: #fff;
  border-radius: 30px;
  font-size: 12px;
  padding-left: 30px;
  background: rgb(22, 23, 26) url(/static/media/search.5e1a204e.svg) no-repeat 10px center;
  background-size: 15px;
  border-color: #222327;
}
/*scroll*/
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a7b0b5 !important;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #3d3f5e !important;
}
/*btm-section*/
.btm-section {
  position: fixed;
  left: 272px;
  right: 280px;
  bottom: 0;
  height: 75px;
  background-color: #1b1c1f;
  padding: 15px;
  z-index: 1;
}
.btm-section ul {
  list-style: none;
  margin: 0px;
  padding: 0;
}
.btm-section ul li {
  display: inline-block;
}
.btm-section ul li a {
  font-size: 14px;
  letter-spacing: 0;
  color: rgb(167, 176, 181);
  align-items: center;
  padding: 5px 8px;
  width: 100%;
}
.btm-section ul li span {
  font-size: 14px;
  letter-spacing: 0;
  color: rgb(167, 176, 181);
  align-items: center;
  padding: 5px 8px;
  width: 100%;
}
/*page*/
.carousel {
  position: relative;
  width: 100%;
  z-index: 0;
}
.carousel-item {
  background-size: cover;
  background-position: center;
  max-height: 250px;
  height: 150px;
  height: 15vw;
}
.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}
.carousel .item-overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(27, 28, 31, 0.8) 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  left: 0;
  right: 0;
}
.carousel-item p {
  letter-spacing: 0.4em;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.carousel-item h3 {
  font-size: 58px;
  font-family: 'Teko', sans-serif;
  margin-bottom: 0;
  line-height: inherit;
}
.carousel-caption {
  top: 0;
  bottom: 0;
  left: 10%;
  right: 10%;
}
.section {
  width: 100%;
  padding-top: 30px;
  margin-bottom: 30px;
}
.section-header {
  width: 100%;
  margin-bottom: 15px;
}
.section-header h3 {
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 0.1em;
  font-size: 18px;
  margin-bottom: 10px;
}
.section-header a.viewlink {
  float: right;
  color: #a7b0b5;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.featured.card {
  align-items: stretch;
  flex-flow: column nowrap;
  flex: 1 1;
  justify-content: flex-start; /*max-width: 350px;*/
  background: #222327;
  position: relative;
  box-shadow: 0px 3.5763px 3.5763px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.featured.card:hover {
  border: 1px solid #a2a5d7;
  cursor: pointer;
}
.featured.card .card-header {
  max-height: 110px;
  min-height: 110px;
  height: 110px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.featured.card .badge.bg-green {
  background: #7baa64;
  position: absolute;
  bottom: 10px;
  left: 15px;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: normal;
  border-radius: 5px;
}
.featured.card .card-body {
  padding: 15px;
}
.featured.card p {
  margin-bottom: 10px;
}
.featured.card h1 {
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-size: 24px;
  margin-bottom: 10px;
}
.featured.card .progress {
  margin-bottom: 10px;
  height: 8px;
  background-color: #3d3f5e;
}
.featured.card .progress-bar {
  background: #a2a5d7;
}

.matches.card {
  align-items: stretch;
  flex-flow: column nowrap;
  flex: 1 1;
  justify-content: flex-start; /*max-width: 350px;*/
  background: #222327;
  position: relative;
  box-shadow: 0px 3.5763px 3.5763px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.matches.card:hover {
  border: 1px solid #a2a5d7;
  cursor: pointer;
}
.matches.card .card-header {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
}
.matches.card .badge.bg-green {
  background: #7baa64;
  position: absolute;
  bottom: 10px;
  left: 15px;
  padding: 8px 10px;
  font-size: 12px;
  border-radius: 5px;
}
.matches.card .card-header h1 {
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-size: 18px;
  margin-bottom: 5px;
}
.matches.card .card-header a {
  color: #a2a5d7;
  font-weight: 600;
}
.matches.card .mavtar {
  width: 35px;
  margin-right: 10px;
  border-radius: 60px;
  border: 2px solid rgb(85, 87, 122);
}
.matches.card .online {
  background-color: rgb(123, 170, 100);
  border: 2px solid #222327;
  width: 12px;
  height: 12px;
  border-radius: 15px;
  position: absolute;
  left: 28px;
  bottom: 9px;
}
.matches.card .offline {
  background-color: rgb(85, 87, 122);
  border: 2px solid rgb(27, 28, 31);
  width: 12px;
  height: 12px;
  border-radius: 15px;
  position: absolute;
  left: 28px;
  bottom: 9px;
}
.matches.card .card-body {
  padding: 15px;
}
.matches.card p {
  margin-bottom: 10px;
}

/*accordion*/
#matchesAccordion .card {
  margin-bottom: 0px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: #fff;
  border-bottom: 1px solid #1b1c1f;
}
#matchesAccordion .card:last-child {
  border-bottom: 0;
}
#matchesAccordion .card .card-header {
  border: 0;
  border-radius: 2px;
  padding: 0;
}
#matchesAccordion .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #2b2d32;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 14px;
  font-weight: bold;
  padding: 25px;
  min-height: 90px;
  border-radius: 0;
}
#matchesAccordion .card .card-header .btn-header-link:focus {
  box-shadow: none;
}
#matchesAccordion .card .card-header .btn-header-link:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}
#matchesAccordion .card .card-header .btn-header-link.collapsed {
  background: #2b2d32;
  color: #fff;
}
#matchesAccordion .card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}
#matchesAccordion .card .collapsing {
  background: transparent;
  line-height: 30px;
}
#matchesAccordion .card .collapse {
  border: 0;
}
#matchesAccordion .card .collapse.show {
  background-color: transparent;
  line-height: 30px;
  color: #fff;
}
.usercard {
  display: flex;
  align-items: center;
  background-color: rgb(27, 28, 31);
  padding: 10px 15px;
  border-radius: 10px;
}
.usercard.bg-lightgray {
  background-color: #2b2d32;
  border: 1px solid #2b2d32;
}
.usercard.bg-lightgray:hover {
  border: 1px solid #a2a5d7;
  cursor: pointer;
}
.usercard:hover {
  cursor: pointer;
}
.avtar {
  position: relative;
}
.avtar .offline {
  background-color: rgb(85, 87, 122);
  border: 2px solid rgb(27, 28, 31);
  width: 12px;
  height: 12px;
  border-radius: 15px;
  position: absolute;
  left: 18px;
  bottom: -3px;
}
.avtar .online {
  background-color: rgb(123, 170, 100);
  border: 2px solid #222327;
  width: 12px;
  height: 12px;
  border-radius: 15px;
  position: absolute;
  left: 18px;
  bottom: -3px;
}

/*chatWindow*/
.chatWindow.show {
  display: block;
}
.chatWindow {
  display: none;
  background-color: #222327;
  width: 300px;
  position: fixed;
  right: 290px;
  bottom: 0;
  z-index: 101;
  box-shadow: rgb(0 0 0 / 80%) 0px 4px 12px;
}
.chatWindow .chat-header {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #1b1c1f;
}
.chatWindow .chat-header .minWindow {
  color: #fff;
  min-width: 25px;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  width: 25px;
  cursor: pointer;
  max-width: 25px;
  height: 25px;
  padding: 0 8px;
  margin-right: 10px;
}
.chatWindow .chat-header .closeWindow {
  opacity: 0.7;
  color: #fff;
  min-width: 25px;
  align-items: center;
  font-size: 15px;
  justify-content: center;
  width: 25px;
  cursor: pointer;
  max-width: 25px;
  height: 25px;
  padding: 0 8px;
}
.chatWindow .chat-header .closeWindow:hover {
  opacity: 1;
}
.chatWindow .chat-body {
  height: 250px;
  padding: 10px;
  overflow-y: auto;
}
.chatWindow .chat-body.nomsg {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1;
  justify-content: center;
  align-items: center;
}
.chatWindow .chat-footer {
  padding: 10px;
  border-top: 1px solid #1b1c1f;
}
.chatWindow .chat-footer .form-control.chat {
  border-radius: 30px;
  font-size: 12px;
  background: rgb(22, 23, 26);
  border-color: #222327;
  width: 90%;
  color: #fff;
  margin-right: 15px;
}
.chatWindow .chat-footer .upload-btn {
  padding-right: 10px;
  -webkit-filter: invert(71%) sepia(2%) saturate(161%) hue-rotate(169deg) brightness(84%) contrast(86%);
          filter: invert(71%) sepia(2%) saturate(161%) hue-rotate(169deg) brightness(84%) contrast(86%);
}
.minimized.chatWindow .chat-header {
  border-top: 2px solid #6e03f6;
}
.minimized.chatWindow .chat-body,
.minimized.chatWindow .chat-footer {
  display: none !important;
}
.chatWindow .chat-bubble {
  width: 100%;
  margin-bottom: 15px;
}
.chatWindow .chat-bubble.me .bubble {
  font-size: 12px;
  background-color: rgb(44, 46, 51);
  width: 100%;
  word-break: break-word;
  padding: 10px;
  letter-spacing: 0.5px;
  margin-left: 0;
  margin-right: 10px;
  margin-top: 5px;
  position: relative;
}
.chatWindow .chat-bubble.me .avtar {
  margin-top: 5px;
}
.chatWindow .chat-bubble.me p.time {
  font-size: 10px;
  color: rgb(162, 165, 215);
  text-align: left;
  margin-bottom: 5px;
}
.chatWindow .chat-bubble .bubble {
  font-size: 12px;
  background-color: rgb(44, 46, 51);
  width: 100%;
  word-break: break-word;
  padding: 10px;
  letter-spacing: 0.5px;
  margin-left: 10px;
  margin-right: 0;
  margin-top: 5px;
  position: relative;
}
.chatWindow .chat-bubble .avtar {
  margin-top: 5px;
}
.chatWindow .chat-bubble p.time {
  font-size: 10px;
  color: rgb(162, 165, 215);
  text-align: right;
  margin-bottom: 5px;
}
/*modal*/
.modal-content {
  background: #222327;
  border-radius: 20px;
}
.modal-header {
  border-bottom: 1px solid #1b1c1f;
}
.modal-header h5.modal-title {
  font-family: 'Noto Sans';
  font-size: 14px;
}
.modal-header h5 {
  font-size: 24px;
  line-height: normal;
  font-family: 'Bebas Neue';
  letter-spacing: 0.05em;
  margin-bottom: 0px;
}
.modal-footer {
  border-top: 1px solid #1b1c1f;
}
.modal .close {
  font-size: 1.2rem;
  font-weight: 100;
  color: #fff;
  text-shadow: 0;
  opacity: 0.5;
  font-family: 'Noto Sans';
  position: absolute;
  right: 20px;
  top: 15px;
}
.modal .close:focus {
  outline: none;
  box-shadow: 0;
}
.modal .progress {
  margin-top: 35px;
  height: 8px;
  background-color: #3d3f5e;
}
.modal .progress-bar {
  background: #a2a5d7;
}
.modal h1 {
  color: #a2a5d7 !important;
  font-size: 36px;
  line-height: normal;
  font-family: 'Bebas Neue';
  letter-spacing: 0.05em;
}
.modal p.animated-text {
  color: #a2a5d7 !important;
}
.modal .tournament-soon {
  min-height: 500px;
}
@-webkit-keyframes animated-ellipsis {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animated-ellipsis {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal .share-link {
  float: right;
  color: #fff;
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.modal .share-link img {
  margin-right: 10px;
}
/*radiobtn*/
.btn-group-toggle {
  align-items: stretch;
  grid-gap: 15px;
  gap: 15px;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 150px), 1fr));
}
.btn.btn-radio {
  background-color: #1b1c1f;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  min-height: 40px;
  font-weight: bold;
  flex-flow: inherit;
  flex: 0 0 auto;
  justify-content: center;
  cursor: default;
  border-radius: 10px;
}
.btn.btn-radio.active {
  background-color: #6e03f6;
  text-align: center;
  color: #fff;
}
.btn.btn-radio .form-control {
  color: #fff;
  background-color: transparent;
  border: 0;
  height: auto;
  max-height: auto;
  padding: 0;
  text-align: center;
}

.media.game-selection {
  border-radius: 10px;
  background-color: #222327;
  padding: 10px;
}
.media.game-selection.active,
.media.game-selection:hover {
  background-color: #6e03f6;
}
.media.game-selection img {
  border-radius: 8px;
  height: 60px;
  object-fit: cover;
}
.option-toggle label {
  color: #a7b0b5;
}
.option-toggle label:hover {
  cursor: pointer;
}
.option-toggle label.active {
  color: #fff;
}
.form-control.search {
  color: #fff;
  border-radius: 30px;
  font-size: 12px;
  padding-left: 30px;
  background: #16171a url(/static/media/search.5e1a204e.svg) no-repeat 10px center;
  background-size: 15px;
  border-color: #222327;
}
.invite-players .media.userlist {
  padding: 10px 15px;
  margin: 5px 0;
}
.invite-players .media.userlist:hover {
  background-color: #6e03f6;
}
.invite-players .remove {
  position: absolute;
  right: 15px;
}

.section.adjust-top-padding {
  padding-top: 0px;
}
h3.row-title {
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 0.1em;
  font-size: 18px;
  margin-bottom: 10px;
}
/*accordion*/
#accordion-row {
  margin-top: 15px;
}
#accordion-row .card {
  margin-bottom: 30px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: #fff;
  border-bottom: 1px solid #1b1c1f;
}
#accordion-row .card:last-child {
  border-bottom: 0;
}
#accordion-row .card .card-header {
  border: 0;
  border-radius: 2px;
  padding: 0;
}
#accordion-row .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #222327;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  border-radius: 0;
}
#accordion-row .card .card-header .btn-header-link:focus {
  box-shadow: none;
}
#accordion-row .card .card-header .btn-header-link:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}
#accordion-row .card .card-header .btn-header-link.collapsed {
  background: #222327;
  color: #fff;
}
#accordion-row .card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}
#accordion-row .card .collapsing {
  background: #222327;
  line-height: 30px;
}
#accordion-row .card .collapse {
  border: 0;
}
#accordion-row .card .collapse.show {
  background-color: #222327;
  line-height: 30px;
  color: #fff;
}
.form-control.dark-rounded {
  background: #16171a;
  border-color: #16171a;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
}

.form-control.dark-rounded:disabled {
  background: #1b1c1f;
  border: none;
}
/*row-grid*/
.row-grid {
  align-items: stretch;
  grid-gap: 15px;
  gap: 15px;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr));
}
.row-grid .column {
  align-items: stretch;
  flex-flow: column nowrap;
  flex: 1 1;
  justify-content: flex-start;
}
.row-grid.four {
  align-items: stretch;
  grid-gap: 15px;
  gap: 15px;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 150px), 1fr));
}
.row-grid .column.four {
  flex-flow: column nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: stretch;
}
.row-grid.three {
  align-items: stretch;
  grid-gap: 15px;
  gap: 15px;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 325px), 1fr));
}

/*Select2 */
/* Assign min-width to container */
.select2-container {
  min-width: 100%;
}
.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
  background: #16171a;
}
.select2-container--flat .select2-container--focus .select2-selection--multiple {
  border: 1px solid #16171a;
}
.select2-container--flat .select2-results__option--highlighted[aria-selected] {
  background: #6e03f6 !important;
  color: #fff;
}
.select2-container--flat .select2-results__option[aria-selected='true'] {
  background: #16171a;
  color: #fff;
  opacity: 0.8;
}
.select2-container--flat .select2-selection--single {
  border-radius: 0px;
}
.select2-container--flat.select2-container--open .select2-selection__arrow b {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
.select2-container--flat span.select2-search input {
  height: 30px !important;
}
.select2-container .select2-choice {
  border: 2px solid #dce4ec;
  height: 36px;
  border-radius: 0px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  text-indent: 1px;
  box-shadow: none;
  background-image: none;
}
.select2-container .select2-choice div {
  border-left: 2px solid #dce4ec;
  border-radius: 0 4px 4px 0;
  background-clip: padding-box;
}
.select2-container .select2-choice .select2-arrow {
  border: 0px;
  border-radius: 0px;
  background: transparent;
  background-image: none;
}
.select2-container *:focus {
  outline: 0px;
}
.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #dce4ec;
  border-radius: 0px;
}
.select2-drop {
  margin-top: -2px;
  border: 2px solid #dce4ec;
  border-top: 0;
  border-radius: 0px !important;
  border-radius: 0 0 6px 6px;
  box-shadow: none;
}
.select2-drop.select2-drop-above {
  margin-top: 2px;
  border-top: 2px solid #dce4ec;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  box-shadow: none;
}
.select2-search {
  margin-top: 3px;
}
.select2-search input {
  height: 26px;
  border: 2px solid #dce4ec;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 2px solid #dce4ec;
  outline: none;
  box-shadow: none;
}
.select2-dropdown-open .select2-choice {
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-dropdown-open .select2-choice .select2-choice div {
  background: transparent;
  border-left: none;
  -webkit-filter: none;
          filter: none;
}
.select2-results {
  padding: 0 0 0 0px;
  margin: 4px 0px 0px 0;
}
.select2-results .select2-highlighted {
  background: #6e03f6;
  color: #fff;
  border-radius: 0px;
}
.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  border: 2px solid #dce4ec;
}
.select2-container-multi.select2-container-active .select2-choices {
  border: 2px solid #dce4ec;
  border-radius: 6px;
  box-shadow: none;
}
.select2-dropdown {
  border-color: #16171a !important;
  border-radius: 10px !important;
}
/*Single Select2*/
.select2-container--flat .select2-selection--single {
  background: #16171a;
  border: 0px;
  height: 34px !important;
  border-radius: 10px;
}
.select2-container--flat .select2-selection--single .select2-selection__rendered {
  color: #fff;
  line-height: 34px;
}
.select2-container--flat .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--flat .select2-selection--single .select2-selection__arrow b {
  border-color: #9d9d9d transparent transparent transparent;
  top: 60%;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  width: 0;
}
.select2-container--flat .select2-selection--single .select2-selection__placeholder {
  color: #fff;
}
.select2-container--flat .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
/*Multiple Select2*/
.select2-container--flat .select2-selection--multiple {
  border: 1px solid #16171a;
  border-radius: 10px;
}
.select2-container--flat .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #16171a;
  cursor: pointer;
}
.select2-container--flat .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--flat .select2-selection--multiple .select2-selection__choice {
  background-color: #16171a;
  color: #fff;
  border: 1px solid #16171a;
  border-radius: 0px;
  padding: 3px 5px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
.select2-container--flat .select2-selection--multiple .select2-selection__choice__remove {
  color: #16171a;
  margin-right: 6px;
  margin-left: 6px;
  float: right;
}
/*Control Switch*/
.custom-control-label::before {
  top: 6px !important;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 4px) !important;
}
.custom-switch .custom-control-label::before {
  border-color: #fff;
  background-color: transparent;
}
.custom-switch .custom-control-label::after {
  background-color: #fff;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6e03f6;
  background-color: #6e03f6;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgb(110 3 246 / 25%);
}
.custom-control-label:hover {
  cursor: pointer;
}

.prize-box {
  background-color: #1b1c1f;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}
.prize-box .cell {
  padding: 10px 5px;
  border-bottom: 1px solid #27282b;
  align-items: center;
}
.prize-box .cell:hover {
  background-color: #2c2e33;
}
.prize-box .cell:last-child {
  border-bottom: 0;
}
.valuecard {
  background-color: #16171a;
  border-radius: 10px;
  padding: 2px 10px;
}

.rawtrment {
  position: relative;
  margin-bottom: 30px;
}
.rawtrment .textarea {
  width: 100%;
  font-size: 14px;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  background-color: #55577a;
  border-color: #55577a;
}
.rawtrment .btn {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
}
.input-group-text {
  border-radius: 10px;
  background-color: #16171a;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}

/*datetimepicker*/
.bootstrap-datetimepicker-widget table {
  font-size: 12px;
}
.bootstrap-datetimepicker-widget table {
  color: #fff;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover,
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover,
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover,
.bootstrap-datetimepicker-widget table td span:hover,
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #6e03f6 !important;
}
.bootstrap-datetimepicker-widget table th {
  color: #fff;
}
.bootstrap-datetimepicker-widget table td.day {
  color: #fff;
}
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #3d3f5e;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  background-color: #222327;
  box-shadow: 0 6px 12px rgb(0 0 0 / 25%);
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-bottom-color: #222327 !important;
}
.bootstrap-datetimepicker-widget table td span {
  color: #fff;
}

.overlay-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.overlay-icon img {
  width: 20px;
  height: 20px;
}
.overlay-icon.lock {
  position: absolute;
  right: 40px;
  bottom: 10px;
}

.media-row {
  align-items: center;
  color: #fff;
  font-size: 12px;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1;
  justify-content: flex-start;
  cursor: pointer;
  padding: 15px 15px;
  -webkit-user-select: none;
          user-select: none;
}
.media-row:hover {
  background-color: #6e03f6;
}
.media-row .avtar {
  flex-flow: inherit;
  flex: 15 1;
  margin: auto;
  margin-right: 10px;
  max-width: 40px;
}
.media-row p {
  flex-flow: inherit;
  flex: 60 1;
  margin: auto;
  padding-right: 10px;
  line-height: normal;
}
.media-row small {
  flex-flow: inherit;
  flex: 0 0 auto;
  font-size: 12px;
  color: rgb(162, 165, 215);
  margin-right: 1ex;
}

.page .panel-tabs li {
  margin-right: 15px;
}
.page .panel-tabs a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 10px;
  position: relative;
  color: #a7b0b5;
  white-space: nowrap;
}
.page .panel-tabs a i {
  opacity: 0.6;
  display: block;
  width: 20px;
  height: 15px;
}
.page .panel-tabs a.active,
.page .panel-tabs a:hover {
  border-bottom: 2px solid #6e03f6;
  color: #fff;
}
.page .panel-tabs a.active i,
.page .panel-tabs a:hover i {
  opacity: 1;
}

.modal .panel-tabs li {
  margin-right: 15px;
}
.modal .panel-tabs a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
  position: relative;
  color: #a7b0b5;
}
.modal .panel-tabs a i {
  opacity: 0.6;
  display: block;
  width: 20px;
  height: 15px;
}
.modal .panel-tabs a.active,
.modal .panel-tabs a:hover {
  border-bottom: 2px solid #6e03f6;
  color: #fff;
}
.modal .panel-tabs a.active i {
  opacity: 1;
}

.btn.tab-btn {
  color: #fff;
  background-color: #1b1c1f;
  padding: 10px 15px;
  font: 14px;
}
.btn.tab-btn.active,
.btn.tab-btn:hover,
.btn.tab-btn:focus {
  background-color: #6e03f6;
  color: #fff;
}

.tournament-card {
  padding: 15px;
  border: 1px solid #2c2e33;
  border-radius: 10px;
}
.tournament-card:hover {
  border-color: #fff;
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}

.usermanage-card {
  padding: 15px;
  border: 1px solid #2c2e33;
  border-radius: 10px;
}
.usermanage-card:hover {
  border-color: #fff;
  cursor: pointer;
}

.ribbon-title {
  align-items: center;
  flex-flow: row nowrap;
  background-color: #16171a;
  flex: 0 0 auto;
  justify-content: space-between;
  border-left: 4px solid #6e03f6;
  padding: 15px 25px 15px 21px;
  margin-bottom: 10px;
  flex-flow: inherit;
  flex: 0 0 auto;
  font-size: 28px;
  font-family: 'Bebas Neue';
  letter-spacing: 0.05em;
  line-height: 1;
}

.card-dark {
  border-radius: 10px;
  background-color: #222327;
}

.progress {
  margin-bottom: 10px;
  height: 8px;
  background-color: #3d3f5e;
}
.progress-bar {
  background: #a2a5d7;
}

.bg-black {
  background-color: #16171a !important;
}

ul.list-dot {
  list-style: none;
  padding: 0;
}
ul.list-dot li {
  padding-left: 1.3em;
  margin-bottom: 10px;
  line-height: normal;
}
ul.list-dot li:before {
  content: '•';
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
}

.grid {
  width: 100%;
  display: flex;
  align-items: center;
}
.grid .column1 {
  width: 25%;
}
.grid .column2 {
  width: 50%;
}
.grid .column3 {
  width: 25%;
}
.result-box .grid {
  border-bottom: 1px solid #16171a;
  margin-bottom: 15px;
}
.result-box .grid:last-child {
  margin-bottom: 0;
}
.border-bottom-darker {
  border-bottom: 1px solid #16171a;
}

/*popover*/
.popthis:hover {
  cursor: pointer;
}
.popover {
  z-index: 99 !important;
}
.popover-body {
  width: 320px;
  background-color: #171829;
  box-shadow: rgb(0 0 0 / 90%) 0px 6px 12px;
}
.bs-popover-auto[x-placement^='top'] > .arrow::after,
.bs-popover-top > .arrow::after {
  border-top-color: #171829 !important;
}
.bs-popover-auto[x-placement^='top'] > .arrow::before,
.bs-popover-top > .arrow::before {
  border-top-color: #171829 !important;
}

.row-grid.two {
  align-items: stretch;
  flex-flow: row nowrap;
  background-color: rgb(38, 39, 42);
  flex: 0 0 auto;
  justify-content: flex-start;
  padding: 10px;
}
.row-grid.two .column.two {
  align-items: center;
  flex-flow: column nowrap;
  flex: 1 1;
  justify-content: center;
  position: relative;
}
.border-right-dark {
  border-right: 1px solid #16171a;
}

.form-control.bg-violate {
  border-color: #55577a !important;
  background-color: #55577a !important;
  color: #fff !important;
}
.shadow-box {
  box-shadow: rgb(0 0 0 / 25%) 0px 2.8538px 2.8538px;
}
.bg-lightdark {
  background-color: #222327 !important;
}
.width-175 {
  width: 175px;
}

/*--owl--*/
.owl-nav {
  font-size: 35px;
  position: absolute;
  top: -35px;
  right: 0;
}
.owl-nav button.owl-next span {
  opacity: 0.5;
  margin-left: 15px;
  text-indent: -9999px;
  display: block;
  background: url(/static/media/next_arrow.3e01592f.svg) no-repeat center center;
  background-size: 8px 18px;
  width: 10px;
  height: 18px;
}
.owl-nav button.owl-prev span {
  opacity: 0.5;
  text-indent: -9999px;
  display: block;
  background: url(/static/media/prev_arrow.f979ef3e.svg) no-repeat center center;
  background-size: 8px 18px;
  width: 10px;
  height: 18px;
}
.owl-nav button.owl-prev span:hover,
.owl-nav button.owl-next span:hover {
  opacity: 1;
}
.owl-carousel .game-img {
  object-fit: contain;
  width: 100%;
  border-radius: 10px;
}
.left-center {
  background-position: left center;
}
.play-btn {
  max-width: 100px;
}

/*resposnive*/
.visible-1299 {
  display: none !important;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 700px;
  }
}
@media (max-width: 1440px) {
  .hidden-1440 {
    display: none !important;
  }
}
@media (max-width: 1299px) {
  .desknotification-box {
    position: -webkit-sticky;
    position: sticky;
  }
  .desk-noti .wrapper {
    margin-top: 0px !important;
  }
  .desk-noti .wrapper {
    margin-top: 0px !important;
  }
  .carousel {
    margin-top: 15px;
  }
  .visible-1299 {
    display: block !important;
  }
  .hidden-1299 {
    display: none !important;
  }
  header .topbar {
    border-bottom: 1px solid #16171a;
  }
  header .status-bar {
    padding: 0;
    border-bottom: 0;
    text-align: right;
  }
  header .user-info {
    width: auto !important;
    background-color: transparent;
    padding: 0;
  }
  .main {
    flex-flow: column;
  }
  .main .right-section {
    width: 100% !important;
    transition: height 0s ease-in-out !important;
    position: fixed;
    height: 100%;
  }
  .main .right-section {
    z-index: 101;
  }
  .main .right-section.collapse:not(.show) {
    display: none;
    height: 100%;
  }
  .right-panel .fixed-tabs {
    position: fixed;
    background: #222327;
    right: 0;
    left: 0;
    width: 100%;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 0 15px;
  }
  .menu li {
    display: inline-flex;
    width: auto;
  }
  .menu li a {
    padding: 5px 10px;
  }
  .menu li a:hover {
    background-color: transparent;
  }
  .menu li a span {
    display: none;
  }
  .menu li a i {
    margin-right: 0;
  }
  .main .page {
    padding: 0;
    padding-top: 0px;
  }
  .main .page .content {
    padding: 0px 15px 60px;
  }
  .page .carousel-item {
    height: 150px;
  }
  .page .carousel-caption {
    left: 5%;
    right: 5%;
  }
  .page .carousel-caption h3 {
    font-size: 34px;
  }
  .page .carousel-caption p {
    font-size: 12px;
    letter-spacing: 0.3em;
  }
  .section {
    padding: 15px 0;
  }
  .right-panel .join {
    width: 100%;
  }
  .btm-section {
    left: 0;
    right: 0;
    bottom: 46px;
  }
  .btm-section ul li a,
  .btm-section ul li span {
    padding: 5px 0;
    width: 100%;
    font-size: 12px;
  }
  .responsive-menu .user-dropdown {
    color: #fff;
    display: block;
    width: 100%;
    position: relative;
    padding: 15px;
  }
  .responsive-menu .user-dropdown:hover {
    text-decoration: none;
    cursor: pointer;
  }
  .responsive-menu .user-dropdown img {
    margin-right: 15px;
  }
  .responsive-menu .user-dropdown .online {
    background-color: rgb(123, 170, 100);
    border: 2px solid #222327;
    width: 12px;
    height: 12px;
    border-radius: 15px;
    position: absolute;
    left: 34px;
    bottom: 12px;
  }
  .responsive-menu .user-dropdown .offline {
    background-color: rgb(85, 87, 122);
    border: 2px solid rgb(27, 28, 31);
    width: 12px;
    height: 12px;
    border-radius: 15px;
    position: absolute;
    left: 34px;
    bottom: 12px;
  }
  .responsive-menu {
    list-style: none;
    margin: 0px;
    padding: 0 0 15px;
    border-bottom: 1px solid #1b1c1f;
    overflow-y: auto;
    padding-bottom: 100px;
  }
  .responsive-menu li {
    width: 100%;
    margin-bottom: 10px;
  }
  .responsive-menu li a {
    display: inline-flex;
    color: rgb(167, 176, 181);
    align-items: center;
    padding: 15px 15px;
    width: 100%;
  }
  .responsive-menu li a:hover {
    text-decoration: none;
    background-color: rgb(44, 46, 51);
  }
  .responsive-menu li.border-bottom {
    border-bottom: 1px solid #1b1c1f !important;
  }
  .responsive-menu li.active a {
    color: #fff;
    background-color: rgb(44, 46, 51);
  }
  /*right-panel-responsive*/
  .right-panel-mobile .panel-tabs a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 0;
    position: relative;
  }
  .right-panel-mobile .panel-tabs a i {
    opacity: 0.6;
    display: block;
    width: 20px;
    height: 15px;
  }
  .right-panel-mobile .panel-tabs a.active {
    border-bottom: 3px solid #6e03f6;
  }
  .right-panel-mobile .panel-tabs a.active i {
    opacity: 1;
  }
  .right-panel-mobile .panel-tabs a i.forum-icon {
    background-size: 15px;
  }
  .right-panel-mobile .panel-tabs a i.users-icon {
    background: url(/static/media/users_icon.49413bd9.svg) no-repeat center center;
    background-size: 20px;
  }
  .right-panel-mobile .panel-tabs a i.bell-icon {
    background-size: 15px;
  }
  .right-panel-mobile .panel-tabs a i.home-icon {
    background: url(/static/media/home_icon.173cc373.svg) no-repeat center center;
    background-size: 15px;
  }
  .right-panel-mobile .panel-tabs a i {
    opacity: 0.6;
  }
  .right-panel-mobile .panel-tabs a .badge {
    position: absolute;
    top: 10px;
    left: 54%;
  }
  .right-panel-mobile .join {
    padding: 15px 0px;
    background: #1b1c1f;
    font-size: 12px;
    width: 100%;
    position: fixed;
    top: auto;
    right: 0;
    left: 0;
  }
  .right-panel-mobile .join a {
    color: #fff;
  }
  .right-panel-mobile .join a:hover {
    text-decoration: none;
  }
  .right-panel-mobile .forum .tab-body {
    padding-top: 60px !important;
    height: calc(100vh - 160px) !important;
  }
  .right-panel-mobile .forum .chat-box {
    padding: 10px;
  }
  .right-panel-mobile .forum .chat-box .form-control.chat {
    border-radius: 30px;
    font-size: 12px;
    background: rgb(22, 23, 26);
    border-color: #222327;
    color: #fff;
    width: 100%;
    margin-right: 15px;
  }
  .right-panel-mobile .forum .chat-box .upload-btn {
    padding-right: 10px;
    -webkit-filter: invert(71%) sepia(2%) saturate(161%) hue-rotate(169deg) brightness(84%) contrast(86%);
            filter: invert(71%) sepia(2%) saturate(161%) hue-rotate(169deg) brightness(84%) contrast(86%);
  }
  .right-panel-mobile .tab-content {
    padding-top: 62px;
    position: fixed;
    top: 0;
    height: 100% !important;
    background: #222327;
    width: 100%;
    z-index: 0;
  }
  .right-panel-mobile .tab-content p {
    font-size: 12px;
  }
  .right-panel-mobile .tab-content p .muser {
    font-weight: 700;
    color: #a2a5d7;
  }
  .right-panel-mobile .tab-content p .xuser {
    font-weight: 700;
    color: #7baa64;
  }
  .right-panel-mobile .fixed-tabs {
    position: fixed;
    background: #222327;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 100;
  }
  .right-panel-mobile .tab-content .tab-body {
    padding-bottom: 80px;
    overflow-y: auto;
    height: 100%;
  }
  .right-panel-mobile .tab-content ul.notification {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .right-panel-mobile .tab-content ul.notification a {
    align-items: center;
    color: #fff;
    font-size: 12px;
    flex-flow: row nowrap;
    flex: 1 1;
    justify-content: flex-start;
    cursor: pointer;
    padding: 15px 10px;
    -webkit-user-select: none;
            user-select: none;
  }
  .right-panel-mobile .tab-content ul.notification a:hover {
    text-decoration: none;
    background-color: #6e03f6;
  }
  .right-panel-mobile .tab-content ul.notification a i {
    flex-flow: inherit;
    flex: 15 1;
    margin: auto;
    max-width: 40px;
  }
  .right-panel-mobile .tab-content ul.notification a img {
    width: 30px;
  }
  .right-panel-mobile .tab-content ul.notification a span {
    flex-flow: inherit;
    flex: 60 1;
    margin: auto;
    padding-right: 10px;
    line-height: normal;
  }
  .right-panel-mobile .tab-content ul.notification a small {
    flex-flow: inherit;
    flex: 0 0 auto;
    font-size: 12px;
    color: rgb(162, 165, 215);
    margin-right: 1ex;
  }
  .right-panel-mobile .tab-content .form-control.search {
    color: #fff;
    border-radius: 30px;
    font-size: 12px;
    padding-left: 30px;
    background: rgb(22, 23, 26) url(/static/media/search.5e1a204e.svg) no-repeat 10px center;
    background-size: 15px;
    border-color: #222327;
  }
  .right-panel-mobile .tab-content > .active {
    background: #222327;
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    top: 60px;
    bottom: 48px;
    z-index: 99;
  }
  /*chatwindow*/
  .chatWindow {
    width: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 101;
    top: 0px;
    box-shadow: none;
  }
  .chatWindow .chat-body {
    height: calc(100% - 110px);
  }
  .section.adjust-top-padding {
    padding-top: 30px;
  }
  .grid {
    display: inline-block;
  }
  .grid .column1 {
    width: 100%;
    margin-bottom: 25px;
  }
  .grid .column2 {
    width: 100%;
  }
  .result-box {
    background-color: transparent !important;
    padding: 0 !important;
  }
  .result-box .grid {
    background-color: #1b1c1f;
    padding: 15px;
    border-radius: 10px;
    border-bottom: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .page .carousel-item {
    height: 250px;
  }
}
@media (max-width: 1100px) {
  .play-btn {
    max-width: 70px;
  }
}
@media (max-width: 991px) {
  .modal-open .modal {
    overflow-y: hidden;
  }
  .modal-dialog-centered {
    display: block;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }
  .modal-content {
    height: 100vh;
    border-radius: 0;
  }
  .forget-password-popup {
    /* height: unset !important; */
    overflow: auto;
  }
  .modal-header.mobile-padding {
    padding-top: 60px;
  }
  .modal .tournament-soon {
    min-height: auto;
  }
  .modal .share-link {
    margin-right: 0px;
  }
  .play-btn {
    max-width: 70px;
  }
}
@media (max-width: 767px) {
  /*flex horizontal scroll*/
  .flex-scrollable-horizotal {
    overflow-x: auto;
    flex-flow: row;
  }
  .flex-scrollable-horizotal li {
    flex: 0 0 25%;
  }
  .play-btn {
    max-width: 50px;
  }
}
@media (max-width: 420px) {
  .section-header a.viewlink {
    float: left;
  }
  .forget-password-popup p.font14.text-center {
    margin-bottom: 120px !important;
  }
  .forgot-password {
    font-size: 13px;
  }
  .email_sent_popup .col-6 {
    max-width: 100%;
    flex: 100% 1;
    margin-bottom: 10px;
  }
}

.cap-lat {
  text-transform: uppercase;
  font-size: 10px;
}

.prize-box .abc {
  content: '$';
  /* font-family: 'Roboto Regular', sans-serif;
	font-size: 1.5em;
	position: absolute;
	left: 5px;
	top: 50%;
	transform: translateY(-50%); */
}

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  color: #6e03f6;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  color: #6e03f6;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid currentColor;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

.indiana-dragging {
  cursor: grabbing !important;
}

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}
.fit-contain {
  object-fit: contain !important;
}

/* fonts */
.text-white {
  color: #fff !important;
}

.placeholder-white::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
}

.placeholder-white::placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
}

/* button */
.btn-width-260 {
  width: 260px;
}

.custom-modal-btn {
  width: 100%;
  border-radius: 10px;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: rgb(193, 96, 96);
  border-color: rgb(193, 96, 96);
}
.btn-outline-danger {
  color: rgb(193, 96, 96);
  border-color: rgb(193, 96, 96);
}

.ladda-button .ladda-label {
  z-index: 0 !important;
}

span.copy-button:hover {
  background: #7baa64;
}

.min-height-60 {
  min-height: 60px !important;
}
.min-height-70 {
  min-height: 70vh;
}
.min-height-80 {
  min-height: 80vh;
}

/* * hide input number arrow */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.rawtrment .textarea {
  min-height: 50px !important;
}

/* * collapse arrow for crete tournament */
#accordion-row .card .card-header .btn-header-link:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url(/static/media/arrow.a2919472.svg) no-repeat;
  background-size: 10px;
  display: block;
}

#accordion-row .card .card-header .btn-header-link.collapsed:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url(/static/media/arrow.a2919472.svg) no-repeat;
  background-size: 10px;
  display: block;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

/* * match css */
/* * collapse arrow for crete match */
#matchesAccordion .card .card-header .btn-header-link:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url(/static/media/arrow.a2919472.svg) no-repeat;
  background-size: 10px;
  display: block;
}

#matchesAccordion .card .card-header .btn-header-link.collapsed:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url(/static/media/arrow.a2919472.svg) no-repeat;
  background-size: 10px;
  display: block;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.match_entry_fee {
  background: #6e03f6 !important;
}
.match_entry_fee > input::-webkit-input-placeholder {
  color: #fff;
}
.match_entry_fee > input::placeholder {
  color: #fff;
}

.amount-input:focus-within {
  border: 2px solid rgb(110 3 246 / 25%);
}
.amount-input > input:focus {
  box-shadow: unset !important;
}

/* react-select */
.react-select {
  font-size: 14px !important;
}
.react-select > div {
  width: 100%;
  border: 0 solid rgba(20, 11, 52, 0.2);
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
}
.react-select .react-select__control {
  background: #16171a;
}
.team_select .css-26l3qy-menu {
  z-index: 3;
}
.add_game_admins .css-26l3qy-menu {
  z-index: 2 !important;
}
.prize_distribution_select label {
  white-space: nowrap !important;
}
.disable_background_color {
  background-color: rgb(85, 87, 122) !important;
  color: #fff !important ;
}
.react-select > div:first-child,
.react-select > div:nth-child(2) {
  border-radius: 16px;
  padding: 0;
}
.react-select > div > div > div {
  color: #fff !important;
}
.react-select__control .react-select__indicator {
  transition: 0.5s ease-in-out;
}
.react-select .react-select__indicator,
.css-tlfecz-indicatorContainer {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEJSURBVHgBddHtUYNAEAbgd+8IJEw02EGswLMCjw6SDkwHsQNLSAekA9LBYQWeFUAHQhL5gYEVcOIMY7L/n/14l6J5HLDDT3Qq3lbZKseV+lzPAxfQPpCIWkJDYudOHROpKLgE0mcdUHVrBERctlDIGgm4sQR+mDlfJtavA5gudODJkwFIEWD7SatsmVMlQyGajw5Ovl1j9LqH74tFwL5vwKSYYT00IW2ynM4d4241d28mo4Majw/WGxXLm2kRT9y9GnuF9WfH8G5j+5vFGS1tG0LVhABbQayk5BQM1YBsxc4fGKBf+JJ7ozrkFhK6JYQ9lk54v00GqdKltLqb/GmpXadKHnfbf2/4Abo1bIHdNPw1AAAAAElFTkSuQmCC) no-repeat !important;
  background-position: center !important;
}
.react-select__menu {
  background: #16171a !important;
  border-radius: 10px !important;
}
.react-select__menu .react-select__option--is-focused {
  background: #6e03f6 !important;
}
.react-select__menu .react-select__option--is-selected {
  background: #6e03f6 !important;
}
.react-select__menu .react-select__option {
  padding: 2px 10px;
  font-size: 14px;
}

/* * form input */
.input_dark_background {
  border: none;
  background-color: rgb(22, 23, 26) !important;
  color: #fff;
}
.form-control:focus {
  color: #fff;
}
.invalid_input_border {
  border: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px rgb(213 0 0 / 60%);
}

.invalid_select_border {
  border: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px rgb(213 0 0 / 60%);
  border-radius: 10px;
}

/* *notification banner */
.desk-noti .desknotification-box {
  display: flex;
}
.desk-noti .wrapper {
}
.desk-noti header .open-menu {
  top: 110px !important;
}
.desk-noti .right-panel-mobile .tab-content > .active {
  top: 110px !important;
}

/* * slick slider custom change  */
.slick-slide {
  width: 315px;
}
.custom-slick-item {
  padding: 0 7px;
}
.custom-slick-item .game-img:hover {
  border: 1px solid #a2a5d7;
  cursor: pointer;
  border-radius: 10px;
}
.slick-slider button.slick-next {
  opacity: 0.5;
  margin-left: 15px;
  text-indent: -9999px;
  display: block;
  background: url(/static/media/next_arrow.3e01592f.svg) no-repeat center center;
  background-size: 8px 18px;
  width: 10px;
  height: 18px;
}
.slick-slider button.slick-prev {
  opacity: 0.5;
  text-indent: -9999px;
  display: block;
  background: url(/static/media/prev_arrow.f979ef3e.svg) no-repeat center center;
  background-size: 8px 18px;
  width: 10px;
  height: 18px;
}
.owl-carousel .game-img {
  /* object-fit: cover !important; */
  min-height: 283px !important;
}
.slick-track {
  margin-left: unset !important;
}

/* *logout popup */
.logout-modal {
  min-width: 600px;
}

/* *user-list popup */
.user-list-modal-content {
  min-height: 95vh;
}

/* * user list modal */
.user-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* *username dropdown arrow */
header a.user-dropdown::after {
  left: 230px !important;
}
header a.open.user-dropdown::after {
  left: 226px !important;
}

/* * toast notification changes */
.dark-success .Toastify__progress-bar--dark {
  background: #03c4a1;
}
.dark-error .Toastify__progress-bar--dark {
  background: rgb(193, 96, 96);
}

.tournament-round {
  flex: 1 1 !important;
}

/* * splash screen */
.splash-screen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: rgb(19, 20, 33);
  z-index: 20;
}
.splash-screen > video {
  width: 100%;
  max-width: 842px;
}

/* * bracket*/
/* can you pl remove old css and apply this one want to see all the things are working well */

/*bracket*/
.parent::-webkit-scrollbar {
  display: block;
}
.parent {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.tournament-round {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: 280px !important;
}
.sc-dlnjwi {
  color: #fff !important;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.seed-item {
  background-color: rgb(27, 28, 31) !important;
  border-radius: 10px !important;
}
.seed-team {
  height: 50px;
}
.seed-team + div {
  background-color: rgb(85, 87, 122) !important;
  opacity: 0.25;
}

.match-info {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.match-info span {
  color: rgb(162, 165, 215);
  font-size: 12px;
  padding-bottom: 5px;
}

.seed:nth-child(2n)::before {
  border-top: 1px solid #55577a !important;
}

[dir='ltr'] .seed:nth-child(2n + 1):not(:last-child)::after {
  border-right: 1px solid #55577a !important;
  border-top-right-radius: 8px;
}
.seed:nth-child(2n + 1):not(:last-child)::after {
  border-top: 1px solid #55577a !important;
  border-top-right-radius: 8px;
  top: calc(50% - -5.5px) !important;
}

[dir='ltr'] .seed:nth-child(2n)::after {
  border-right: 1px solid #55577a;
}
.seed:nth-child(2n)::after {
  border-bottom: 1px solid #55577a !important;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #55577a !important;
  top: 6.5px !important;
}

.seed-item .usercard {
  background-color: transparent !important;
  padding: 10px 0 !important;
}
.seed-time {
  padding-top: 5px;
}

/* *match detail footer */

.accept-reject-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

/* *game developer page css */
.horizontal-form-grid {
  align-items: center;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  padding: 10px 15px;
  display: flex;
}
.horizontal-form-grid:hover {
  background-color: #2c2e33 !important;
}
.label-hz {
  display: flex;
  flex-flow: inherit;
  flex: 0 0 auto;
  color: rgb(162, 165, 215);
  width: 125px;
  min-width: 125px;
  max-width: 125px;
}
.label-hz label {
  flex-flow: inherit;
  flex: 0 0 auto;
  color: rgb(162, 165, 215);
  width: 125px;
  min-width: 125px;
  max-width: 125px;
}
.field-hz {
  flex-flow: inherit;
  flex: 1 1;
}
.form-control:disabled,
.form-control[readonly] {
  /* background-color: #55577a !important; */
  color: #fff !important;
  border: 1px solid #3d3f5e;
}
.input-group-text {
  line-height: 1 !important;
  border: 1px solid #3d3f5e;
  background: transparent;
}
.input-group-text i.fas {
  padding: 0 10px;
}
.input-group-text:hover {
  background-color: #55577a !important;
  cursor: pointer;
}

/* *tournament detail prizes */
.tournament-detail-prizes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.tournament-detail-prize-box {
  width: 48%;
}

.popup-cancel-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-tournament p {
  font-size: 14px;
}
.success-tournament h3 {
  font-size: 48px;
}
.success-tournament h1 svg {
  font-size: max(100px, min(200px, 13vw));
}
.success-tournament {
  max-width: 603px;
  margin: 0 auto;
  text-align: center;
}
.success-tournament .input-group {
  max-width: 250px;
  margin: 0 auto;
}
.success-tournament button {
  max-width: 250px;
  margin: 0 auto;
}
.tournament-detail-result-box {
  width: 100%;
}
.btn-header-link2 {
  text-transform: unset !important;
}
.unread-notification {
  border-right: 5px solid #6406dd;
  background: rgb(44, 46, 53);
}

.match_popup_background {
  background-color: rgb(85, 87, 122);
  width: 65px;
}

.match_history_status {
  justify-content: space-between;
}

.responsive-menu a {
  justify-content: space-between;
}
.responsive-menu .icon {
  width: 15px !important;
}

.text-danger.text-center.footer-fund-error {
  width: 100%;
}

.text-preparing {
  color: rgb(162, 165, 215);
}
.text-invited {
  color: rgb(246, 207, 3);
}
.text-aborted {
  color: rgb(167, 176, 181) !important;
}
.aborted_status h1 {
  color: rgb(167, 176, 181) !important;
}
.clear.text-violate {
  font-size: 27px;
}
.notification-landing {
  display: flex !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  justify-content: center !important;
  align-items: center;
}
.notification-landing p {
  width: 100%;
}
.fontSize {
  font-size: 13px;
}

.distribution_weights {
  width: 100% !important;
  align-items: center;
}
.distribution_card_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* mobile modal footer */
.mobile-model-footer {
  margin-bottom: 50px;
}

.match_card_winningPosition {
  width: 33px;
}

.tournament_detail_popup > div {
  overflow-y: auto;
  display: block;
}

.featured.card {
  height: 320px;
}

.tooltip-border {
  border-color: 2px solid rgb(61, 63, 94);
}

.sub-match {
  padding: 15px;
}

.sub-math-bestOf .react-select {
  width: 300px;
}

.sub-match a {
  cursor: pointer;
  text-decoration: underline !important;
}

.sub-match .react-select__menu.css-26l3qy-menu {
  z-index: 2;
}

.date-card-time-field label {
  position: relative;
  max-width: 90px;
}

.date-card-time-field svg {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.date-card-select-field {
  max-width: 190px;
  width: 100%;
}

.date-card-date-field {
  position: relative;
  height: 100%;
  max-width: 175px;
}

.date-card-date-field svg {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.sub-config-date,
.sub-config-time {
  position: relative;
}

.sub-config-date svg,
.sub-config-time svg {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.sub-config-main-div {
  display: flex;
  flex-wrap: wrap;
}

.sub-config-time {
  max-width: 90px;
}

.sub-config-select {
  max-width: 190px;
  width: 100%;
}

.sub-config-date {
  max-width: 175px;
}

.date-card-date-field input,
.date-card-time-field input,
.sub-config-date input,
.sub-config-time input {
  min-height: 38px;
}

@media (max-width: 991px) {
  .logout-modal {
    min-width: unset !important;
  }
  /* .modal-content {
		height: 100vh;
		border-radius: 0;
		overflow: auto;
	}
	.login-popup-signup {
		overflow: auto;
	}
	.modal-dialog-scrollable .modal-content {
		overflow: auto;
		max-height: unset !important;
		height: 100vh;
	}
	.modal-footer {
		position: relative;
		bottom: 70px;
	}
	.create-match-popup .modal-footer {
		position: relative;
		bottom: unset;
	}
	.modal-header.mobile-padding > div {
		max-width: 240px;
		width: 100%;
		margin: 0 auto;
		height: auto !important;
	}
	.modal-header.mobile-padding > div img {
		width: 100%;
	} */
}

@media (max-width: 767px) {
  .flex-scrollable-horizotal li {
    flex: 0 0 50% !important;
    max-width: -webkit-min-content;
    max-width: min-content;
    width: auto;
  }
}
@media (max-width: 620px) {
  .row-grid.prize_distribution_select {
    display: block !important;
  }
}

@media (max-width: 575px) {
  .match-result-span {
    word-break: break-all;
    margin-left: 10px;
  }
  .date-card-main-div {
    flex-wrap: wrap;
  }

  /* .result-box .col-10 {
		max-width: 100%;
		flex: 100%;
	}
	.result-box .form-group .btn-primary {
		width: auto;
	} */
}
@media (max-width: 425px) {
  .tournament-current-rankings {
    width: 50%;
    max-width: 50%;
    flex: 50% 1;
    margin-bottom: 20px;
  }
  .flex-scrollable-horizotal li {
    flex: 0 0 60% !important;
  }
  .sub-match-round-title {
    width: auto !important;
  }
  .sub-match-round-select-div {
    display: block !important;
  }
  .sub-match-round-remove {
    text-align: left !important;
    margin-top: 7px;
  }
  .forgot-password,
  .remember-me {
    width: 50% !important;
  }
  .desknotification-box {
    height: auto !important;
  }
}

@media (max-width: 350px) {
  .date-card-time-field {
    margin-top: 0.5rem;
  }
}


