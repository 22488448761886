.cursor-pointer {
  cursor: pointer;
}
.fit-contain {
  object-fit: contain !important;
}

/* fonts */
.text-white {
  color: #fff !important;
}

.placeholder-white::placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
}

/* button */
.btn-width-260 {
  width: 260px;
}

.custom-modal-btn {
  width: 100%;
  border-radius: 10px;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: rgb(193, 96, 96);
  border-color: rgb(193, 96, 96);
}
.btn-outline-danger {
  color: rgb(193, 96, 96);
  border-color: rgb(193, 96, 96);
}

.ladda-button .ladda-label {
  z-index: 0 !important;
}

span.copy-button:hover {
  background: #7baa64;
}

.min-height-60 {
  min-height: 60px !important;
}
.min-height-70 {
  min-height: 70vh;
}
.min-height-80 {
  min-height: 80vh;
}

/* * hide input number arrow */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.rawtrment .textarea {
  min-height: 50px !important;
}

/* * collapse arrow for crete tournament */
#accordion-row .card .card-header .btn-header-link:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url('../images/arrow.svg') no-repeat;
  background-size: 10px;
  display: block;
}

#accordion-row .card .card-header .btn-header-link.collapsed:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url('../images/arrow.svg') no-repeat;
  background-size: 10px;
  display: block;
  transform: rotate(180deg);
}

/* * match css */
/* * collapse arrow for crete match */
#matchesAccordion .card .card-header .btn-header-link:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url('../images/arrow.svg') no-repeat;
  background-size: 10px;
  display: block;
}

#matchesAccordion .card .card-header .btn-header-link.collapsed:after {
  content: '';
  width: 10px;
  height: 6px;
  background: url('../images/arrow.svg') no-repeat;
  background-size: 10px;
  display: block;
  transform: rotate(180deg);
}

.match_entry_fee {
  background: #6e03f6 !important;
}
.match_entry_fee > input::placeholder {
  color: #fff;
}

.amount-input:focus-within {
  border: 2px solid rgb(110 3 246 / 25%);
}
.amount-input > input:focus {
  box-shadow: unset !important;
}

/* react-select */
.react-select {
  font-size: 14px !important;
}
.react-select > div {
  width: 100%;
  border: 0 solid rgba(20, 11, 52, 0.2);
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
}
.react-select .react-select__control {
  background: #16171a;
}
.team_select .css-26l3qy-menu {
  z-index: 3;
}
.add_game_admins .css-26l3qy-menu {
  z-index: 2 !important;
}
.prize_distribution_select label {
  white-space: nowrap !important;
}
.disable_background_color {
  background-color: rgb(85, 87, 122) !important;
  color: #fff !important ;
}
.react-select > div:first-child,
.react-select > div:nth-child(2) {
  border-radius: 16px;
  padding: 0;
}
.react-select > div > div > div {
  color: #fff !important;
}
.react-select__control .react-select__indicator {
  transition: 0.5s ease-in-out;
}
.react-select .react-select__indicator,
.css-tlfecz-indicatorContainer {
  background: url('../images/bottom_arrow.png') no-repeat !important;
  background-position: center !important;
}
.react-select__menu {
  background: #16171a !important;
  border-radius: 10px !important;
}
.react-select__menu .react-select__option--is-focused {
  background: #6e03f6 !important;
}
.react-select__menu .react-select__option--is-selected {
  background: #6e03f6 !important;
}
.react-select__menu .react-select__option {
  padding: 2px 10px;
  font-size: 14px;
}

/* * form input */
.input_dark_background {
  border: none;
  background-color: rgb(22, 23, 26) !important;
  color: #fff;
}
.form-control:focus {
  color: #fff;
}
.invalid_input_border {
  border: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px rgb(213 0 0 / 60%);
}

.invalid_select_border {
  border: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 6px rgb(213 0 0 / 60%);
  border-radius: 10px;
}

/* *notification banner */
.desk-noti .desknotification-box {
  display: flex;
}
.desk-noti .wrapper {
}
.desk-noti header .open-menu {
  top: 110px !important;
}
.desk-noti .right-panel-mobile .tab-content > .active {
  top: 110px !important;
}

/* * slick slider custom change  */
.slick-slide {
  width: 315px;
}
.custom-slick-item {
  padding: 0 7px;
}
.custom-slick-item .game-img:hover {
  border: 1px solid #a2a5d7;
  cursor: pointer;
  border-radius: 10px;
}
.slick-slider button.slick-next {
  opacity: 0.5;
  margin-left: 15px;
  text-indent: -9999px;
  display: block;
  background: url('../images/next_arrow.svg') no-repeat center center;
  background-size: 8px 18px;
  width: 10px;
  height: 18px;
}
.slick-slider button.slick-prev {
  opacity: 0.5;
  text-indent: -9999px;
  display: block;
  background: url('../images/prev_arrow.svg') no-repeat center center;
  background-size: 8px 18px;
  width: 10px;
  height: 18px;
}
.owl-carousel .game-img {
  /* object-fit: cover !important; */
  min-height: 283px !important;
}
.slick-track {
  margin-left: unset !important;
}

/* *logout popup */
.logout-modal {
  min-width: 600px;
}

/* *user-list popup */
.user-list-modal-content {
  min-height: 95vh;
}

/* * user list modal */
.user-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* *username dropdown arrow */
header a.user-dropdown::after {
  left: 230px !important;
}
header a.open.user-dropdown::after {
  left: 226px !important;
}

/* * toast notification changes */
.dark-success .Toastify__progress-bar--dark {
  background: #03c4a1;
}
.dark-error .Toastify__progress-bar--dark {
  background: rgb(193, 96, 96);
}

.tournament-round {
  flex: 1 !important;
}

/* * splash screen */
.splash-screen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: rgb(19, 20, 33);
  z-index: 20;
}
.splash-screen > video {
  width: 100%;
  max-width: 842px;
}

/* * bracket*/
/* can you pl remove old css and apply this one want to see all the things are working well */

/*bracket*/
.parent::-webkit-scrollbar {
  display: block;
}
.parent {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.tournament-round {
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: 280px !important;
}
.sc-dlnjwi {
  color: #fff !important;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.seed-item {
  background-color: rgb(27, 28, 31) !important;
  border-radius: 10px !important;
}
.seed-team {
  height: 50px;
}
.seed-team + div {
  background-color: rgb(85, 87, 122) !important;
  opacity: 0.25;
}

.match-info {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.match-info span {
  color: rgb(162, 165, 215);
  font-size: 12px;
  padding-bottom: 5px;
}

.seed:nth-child(2n)::before {
  border-top: 1px solid #55577a !important;
}

[dir='ltr'] .seed:nth-child(2n + 1):not(:last-child)::after {
  border-right: 1px solid #55577a !important;
  border-top-right-radius: 8px;
}
.seed:nth-child(2n + 1):not(:last-child)::after {
  border-top: 1px solid #55577a !important;
  border-top-right-radius: 8px;
  top: calc(50% - -5.5px) !important;
}

[dir='ltr'] .seed:nth-child(2n)::after {
  border-right: 1px solid #55577a;
}
.seed:nth-child(2n)::after {
  border-bottom: 1px solid #55577a !important;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #55577a !important;
  top: 6.5px !important;
}

.seed-item .usercard {
  background-color: transparent !important;
  padding: 10px 0 !important;
}
.seed-time {
  padding-top: 5px;
}

/* *match detail footer */

.accept-reject-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

/* *game developer page css */
.horizontal-form-grid {
  align-items: center;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: flex-start;
  padding: 10px 15px;
  display: flex;
}
.horizontal-form-grid:hover {
  background-color: #2c2e33 !important;
}
.label-hz {
  display: flex;
  flex-flow: inherit;
  flex: 0 0 auto;
  color: rgb(162, 165, 215);
  width: 125px;
  min-width: 125px;
  max-width: 125px;
}
.label-hz label {
  flex-flow: inherit;
  flex: 0 0 auto;
  color: rgb(162, 165, 215);
  width: 125px;
  min-width: 125px;
  max-width: 125px;
}
.field-hz {
  flex-flow: inherit;
  flex: 1 1 0;
}
.form-control:disabled,
.form-control[readonly] {
  /* background-color: #55577a !important; */
  color: #fff !important;
  border: 1px solid #3d3f5e;
}
.input-group-text {
  line-height: 1 !important;
  border: 1px solid #3d3f5e;
  background: transparent;
}
.input-group-text i.fas {
  padding: 0 10px;
}
.input-group-text:hover {
  background-color: #55577a !important;
  cursor: pointer;
}

/* *tournament detail prizes */
.tournament-detail-prizes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.tournament-detail-prize-box {
  width: 48%;
}

.popup-cancel-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-tournament p {
  font-size: 14px;
}
.success-tournament h3 {
  font-size: 48px;
}
.success-tournament h1 svg {
  font-size: max(100px, min(200px, 13vw));
}
.success-tournament {
  max-width: 603px;
  margin: 0 auto;
  text-align: center;
}
.success-tournament .input-group {
  max-width: 250px;
  margin: 0 auto;
}
.success-tournament button {
  max-width: 250px;
  margin: 0 auto;
}
.tournament-detail-result-box {
  width: 100%;
}
.btn-header-link2 {
  text-transform: unset !important;
}
.unread-notification {
  border-right: 5px solid #6406dd;
  background: rgb(44, 46, 53);
}

.match_popup_background {
  background-color: rgb(85, 87, 122);
  width: 65px;
}

.match_history_status {
  justify-content: space-between;
}

.responsive-menu a {
  justify-content: space-between;
}
.responsive-menu .icon {
  width: 15px !important;
}

.text-danger.text-center.footer-fund-error {
  width: 100%;
}

.text-preparing {
  color: rgb(162, 165, 215);
}
.text-invited {
  color: rgb(246, 207, 3);
}
.text-aborted {
  color: rgb(167, 176, 181) !important;
}
.aborted_status h1 {
  color: rgb(167, 176, 181) !important;
}
.clear.text-violate {
  font-size: 27px;
}
.notification-landing {
  display: flex !important;
  position: sticky !important;
  justify-content: center !important;
  align-items: center;
}
.notification-landing p {
  width: 100%;
}
.fontSize {
  font-size: 13px;
}

.distribution_weights {
  width: 100% !important;
  align-items: center;
}
.distribution_card_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* mobile modal footer */
.mobile-model-footer {
  margin-bottom: 50px;
}

.match_card_winningPosition {
  width: 33px;
}

.tournament_detail_popup > div {
  overflow-y: auto;
  display: block;
}

.featured.card {
  height: 320px;
}

.tooltip-border {
  border-color: 2px solid rgb(61, 63, 94);
}

.sub-match {
  padding: 15px;
}

.sub-math-bestOf .react-select {
  width: 300px;
}

.sub-match a {
  cursor: pointer;
  text-decoration: underline !important;
}

.sub-match .react-select__menu.css-26l3qy-menu {
  z-index: 2;
}

.date-card-time-field label {
  position: relative;
  max-width: 90px;
}

.date-card-time-field svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.date-card-select-field {
  max-width: 190px;
  width: 100%;
}

.date-card-date-field {
  position: relative;
  height: 100%;
  max-width: 175px;
}

.date-card-date-field svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.sub-config-date,
.sub-config-time {
  position: relative;
}

.sub-config-date svg,
.sub-config-time svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.sub-config-main-div {
  display: flex;
  flex-wrap: wrap;
}

.sub-config-time {
  max-width: 90px;
}

.sub-config-select {
  max-width: 190px;
  width: 100%;
}

.sub-config-date {
  max-width: 175px;
}

.date-card-date-field input,
.date-card-time-field input,
.sub-config-date input,
.sub-config-time input {
  min-height: 38px;
}

@media (max-width: 991px) {
  .logout-modal {
    min-width: unset !important;
  }
  /* .modal-content {
		height: 100vh;
		border-radius: 0;
		overflow: auto;
	}
	.login-popup-signup {
		overflow: auto;
	}
	.modal-dialog-scrollable .modal-content {
		overflow: auto;
		max-height: unset !important;
		height: 100vh;
	}
	.modal-footer {
		position: relative;
		bottom: 70px;
	}
	.create-match-popup .modal-footer {
		position: relative;
		bottom: unset;
	}
	.modal-header.mobile-padding > div {
		max-width: 240px;
		width: 100%;
		margin: 0 auto;
		height: auto !important;
	}
	.modal-header.mobile-padding > div img {
		width: 100%;
	} */
}

@media (max-width: 767px) {
  .flex-scrollable-horizotal li {
    flex: 0 0 50% !important;
    max-width: min-content;
    width: auto;
  }
}
@media (max-width: 620px) {
  .row-grid.prize_distribution_select {
    display: block !important;
  }
}

@media (max-width: 575px) {
  .match-result-span {
    word-break: break-all;
    margin-left: 10px;
  }
  .date-card-main-div {
    flex-wrap: wrap;
  }

  /* .result-box .col-10 {
		max-width: 100%;
		flex: 100%;
	}
	.result-box .form-group .btn-primary {
		width: auto;
	} */
}
@media (max-width: 425px) {
  .tournament-current-rankings {
    width: 50%;
    max-width: 50%;
    flex: 50%;
    margin-bottom: 20px;
  }
  .flex-scrollable-horizotal li {
    flex: 0 0 60% !important;
  }
  .sub-match-round-title {
    width: auto !important;
  }
  .sub-match-round-select-div {
    display: block !important;
  }
  .sub-match-round-remove {
    text-align: left !important;
    margin-top: 7px;
  }
  .forgot-password,
  .remember-me {
    width: 50% !important;
  }
  .desknotification-box {
    height: auto !important;
  }
}

@media (max-width: 350px) {
  .date-card-time-field {
    margin-top: 0.5rem;
  }
}

